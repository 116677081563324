/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}
html, body {
  height: 100vh;
  width: 100vw;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selected {
  background-color: black;
  color: white;
}
.listbox li {
  cursor: pointer;
  padding: 2px 2px 0 7px;
  border-radius: 5px;
}
.h-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.bold {
  font-weight: bold;
}
.overflow-auto {
  overflow: auto;
}
.tag {
  background-color: lightgray;
  border: solid 1px black;
  border-radius: 3px;
  padding: 2px 4px;
}
.sm-icon-btn {
  width: 24px !important;
  height: 24px !important;
  padding: 2px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }
  span {
    width: 24px !important;
    height: 24px !important;
  }
}
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

button.mat-flat-button {
  margin-right: 10px;
}
.compact-menu .mat-menu-item {
  height: 24px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 24px;
}
.pointer {
  cursor: pointer;
}

// SCROLLBAR CLASSES *********************************
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';